<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="teacherTeamSaveModal"
      centered
      :title="isCreated ? 'Thêm tổ bộ môn' : 'Cập nhật tổ bộ môn'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên tổ bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên tổ bộ môn"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetItem.name"
              name="name"
              placeholder="Nhập tên tổ bộ môn"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="code">
          <template v-slot:label>
            Mã tổ bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mã tổ bộ môn"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetItem.code"
              name="code"
              placeholder="Nhập mã tổ bộ môn"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="department">
          <template v-slot:label>
            Khoa/bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/bộ môn"
            rules="required"
          >
            <v-select
              v-model="targetItem.departmentId"
              :options="departments"
              :reduce="option => option.value"
              @input="onChangeDepartments"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Tổ trưởng"
          label-for="leaderId"
        >
          <v-select
            v-model="targetItem.leaderId"
            :options="teacherOptions"
            :reduce="option => option.value"
          />
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetItem.status"
              :options="statuses"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetItem.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherTeamSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'TeacherTeamSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      filterTeacher: {
        currentPage: 1,
        itemsPerPage: 1000,
        departmentId: null,
        teamId: null,
        name: '',
        code: '',
        status: 1,
        sort: '',
      },
      teacherOptions: [],
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        departmentId: null,
        leaderId: null,
        status: 1,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'teacherTeam/statuses',
      departments: 'dropdown/departments',
      teachers: 'teacher/teachers',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createTeacherTeams: 'teacherTeam/createTeacherTeams',
      updateTeacherTeams: 'teacherTeam/updateTeacherTeams',
      readTeachers: 'teacher/readTeachers',
    }),
    async onChangeDepartments() {
      this.filterTeacher.departmentId = this.targetItem.departmentId
      await this.readTeachers(this.filterTeacher)
      this.teacherOptions = this.teachers.map(item => ({ value: item.id, label: item.name }))
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
        this.filterTeacher.departmentId = this.targetItem.departmentId
        this.onChangeDepartments()
        this.teachers.map(item => ({ value: item.id, label: item.name }))
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        departmentId: null,
        leaderId: null,
        status: 1,
        orderNo: 1,
      }
      this.teacherOptions = []
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createTeacherTeams(this.targetItem)
            : await this.updateTeacherTeams(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('teacherTeamSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
